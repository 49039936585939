<template>
    <div class="auth-wrapper auth-v1 px-2" v-if="!processing">
        <div class="auth-inner py-2">

            <b-overlay :show="loading"
                       :variant="variant"
                       :opacity="opacity"
                       :blur="blur"
                       spinner-variant="primary"
                       spinner-type="grow"
                       spinner-large
                       rounded="sm">
                <b-card class="mb-0">
                    <b-link class="brand-logo" to="/">
                        <vuexy-logo />
                    </b-link>

                    <!--<b-card-title class="mb-1">
                        Welcome to Landingly! 👋
                    </b-card-title>-->
                    <b-card-text class="mb-2">
                        Please sign-in to your account
                    </b-card-text>

                    <b-card-text class="mb-2" v-if="error">
                            <div class="error" v-html="error">
                                {{ error }}
                            </div>
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="loginForm"
                                         #default="{invalid}">
                        <b-form class="auth-login-form mt-2"
                                @submit.prevent="login">
                            <!-- email -->
                            <b-form-group label="Email"
                                          label-for="login-email">
                                <validation-provider #default="{ errors }"
                                                     name="Email"
                                                     vid="email"
                                                     rules="required|email">
                                    <b-form-input id="login-email"
                                                  v-model="userEmail"
                                                  :state="errors.length > 0 ? false:null"
                                                  name="login-email"
                                                  placeholder="email" />
                                    <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                </validation-provider>
                            </b-form-group>

                            <!-- forgot password -->
                            <b-form-group>
                                <div class="d-flex justify-content-between">
                                    <label for="login-password">Password</label>
                                    <b-link :to="{name:'auth-forgot-password'}">
                                        <small>Forgot Password?</small>
                                    </b-link>
                                </div>
                                <validation-provider #default="{ errors }"
                                                     name="Password"
                                                     vid="password"
                                                     rules="required">
                                    <b-input-group class="input-group-merge"
                                                   :class="errors.length > 0 ? 'is-invalid':null">
                                        <b-form-input id="login-password"
                                                      v-model="password"
                                                      :state="errors.length > 0 ? false:null"
                                                      class="form-control-merge"
                                                      :type="passwordFieldType"
                                                      name="login-password"
                                                      placeholder="Password" />
                                        <b-input-group-append is-text>
                                            <feather-icon class="cursor-pointer"
                                                          :icon="passwordToggleIcon"
                                                          @click="togglePasswordVisibility" />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                </validation-provider>
                            </b-form-group>

                            <!--<b-form-group>
                                <validation-provider #default="{ errors }">
                                    <b-form-checkbox id="login-privacy-policy"
                                                     v-model="status"
                                                     name="checkbox-1">
                                        I've read and accept the
                                        <b-link>terms & conditions</b-link>
                                    </b-form-checkbox>
                                </validation-provider>
                            </b-form-group>-->
                            <!-- checkbox -->
                            <!--<b-form-group>
                                <b-form-checkbox id="remember-me"
                                                 v-model="status"
                                                 name="checkbox-1">
                                    Remember Me
                                </b-form-checkbox>
                            </b-form-group>-->
                            <!-- submit buttons -->


                            <b-button type="submit"
                                      variant="primary"
                                      block
                                      :disabled="invalid">
                                Sign in
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <!--<b-card-text class="text-center mt-2">
                        <span>New on our platform? </span>
                        <b-link :to="{name:'auth-register'}">
                            <span>Create an account</span>
                        </b-link>
                    </b-card-text>-->
                    <!--<div class="divider my-2">
                        <div class="divider-text">
                            or
                        </div>
                    </div>-->
                    <!-- social button -->
                    <!--<div class="auth-footer-btn d-flex justify-content-center">
                        <b-button href="javascript:void(0)"
                                  variant="facebook">
                            <feather-icon icon="FacebookIcon" />
                        </b-button>
                        <b-button href="javascript:void(0)"
                                  variant="twitter">
                            <feather-icon icon="TwitterIcon" />
                        </b-button>
                        <b-button href="javascript:void(0)"
                                  variant="google">
                            <feather-icon icon="MailIcon" />
                        </b-button>
                        <b-button href="javascript:void(0)"
                                  variant="github">
                            <feather-icon icon="GithubIcon" />
                        </b-button>
                    </div>-->
                </b-card>

            </b-overlay>
        </div>
    </div>
</template>

<script>
    /* eslint-disable global-require */
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import VuexyLogo from '@core/layouts/components/Logo.vue'
    import {
        BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox, BOverlay,
    } from 'bootstrap-vue'
    import useJwt from '@/auth/jwt/useJwt'
    import { required, email } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import store from '@/store/index'
    import { getHomeRouteForLoggedInUser } from '@/auth/utils'
    import { $themeConfig } from '@themeConfig'

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import qs from "qs"
import jwt from '@/auth/jwt/useJwt'

    export default {
        metaInfo: {
            title: 'Landingly | Login'
        },
        components: {
            BButton,
            BForm,
            BFormInput,
            BFormGroup,
            BCard,
            BCardTitle,
            BLink,
            VuexyLogo,
            BCardText,
            BInputGroup,
            BInputGroupAppend,
            BFormCheckbox,
            ValidationProvider,
            ValidationObserver,
            BOverlay
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                loading: false,
                processing: false,
                variant: 'light',
                opacity: 0.85,
                blur: '2px',
                status: '',
                password: '',
                userEmail: '',
                sideImg: require('@/assets/images/pages/login-v2.svg'),

                // validation rules
                WebAPIURL: $themeConfig.app.apiURL,
                required,
                email,
                error:null,
                encryptionKey: $themeConfig.app.encryptionKey,
            }
        },
        created() {
            const urlParams = new URLSearchParams(window.location.search);
            const loginemail = urlParams.get('loginemail');
            const code = urlParams.get('code');
            
            if (loginemail && code) {
                this.processing = true;
                this.AutoLogin(loginemail, code);
            }
            //else {
            //    this.$toast({
            //        component: ToastificationContent,
            //        position: 'top-right',

            //        props: {
            //            title: 'Invalid Request',
            //            icon: 'BellIcon',
            //            variant: 'danger'
            //        },
            //    })
            //}
        },

        computed: {
            passwordToggleIcon() {
                return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
            imgUrl() {
                if (store.state.appConfig.layout.skin === 'dark') {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
                    return this.sideImg
                }
                return this.sideImg
            },
        },

        methods: {
            login() {
                this.$refs.loginForm.validate().then(success => {
                    if (success) {
                        const self = this;
                        this.loading = true;
                        useJwt.login({
                            email: this.userEmail,
                            password: this.password,
                            IsAllow:null
                        })
                            .then(response => {

                                const ability = [
                                    {
                                        action: 'manage',
                                        subject: 'all',
                                    },
                                ]

                                console.log(response);
                                console.log(response.data);
                                console.log(response.data.subDomain);

                                response.data.ability = ability
                                var expirytime = $themeConfig.app.RefreshTokenExpiryTime;
                                useJwt.setToken(response.data.access_token)
                                //console.log('refresh token:: ' + response.data.refresh_token);
                                useJwt.setRefreshToken(response.data.refresh_token)
                                localStorage.setItem('User_Name', JSON.stringify(response.data.fullName))
                                useJwt.setUserEmail(JSON.stringify(response.data.email));
                                useJwt.setUserRole(response.data.role);
                                useJwt.setUserId(response.data.userId);
                                var currDate = new Date();
                                var expiryDate = new Date(currDate.setSeconds(currDate.getSeconds() + response.data.expires_in));
                                //console.log(expiryDate);
                                useJwt.setTokenExpiry(expiryDate);
                                var refreshTokenExpiryTime = new Date(currDate.setSeconds(currDate.getSeconds() + expirytime));
                                // console.log(refreshTokenExpiryTime);
                                useJwt.setRefreshTokenExpiry(refreshTokenExpiryTime);

                                this.$ability.update(ability)
                                this.submdomain = response.data.subDomain;
                                this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', 3)
                                this.loading = false;
                                if (response.data.subDomain == null || response.data.subDomain == "") {
                                    this.$router.replace('/setupuseraccount')
                                }
                                else {
                                    useJwt.setSubDomain(response.data.subDomain);
                                    this.$router.replace(getHomeRouteForLoggedInUser(response.data.role))
                                        .then(() => {
                                            this.$toast({
                                                component: ToastificationContent,
                                                position: 'top-right',
                                                props: {
                                                    title: `Welcome ${response.data.fullName || response.data.username}`,
                                                    icon: 'CoffeeIcon',
                                                    variant: 'success',
                                                    text: `You have successfully logged in. Now you can start to explore.`,
                                                },
                                            })
                                        })
                                }

                            })
                            .catch(error => {
                                var errorMsg = error.response.data.error;
                                
                                if (errorMsg == "invalid_grant") {
                                    this.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',

                                        props: {
                                            title: 'Invalid Login Attempt',
                                            icon: 'BellIcon',
                                            variant: 'danger',
                                            text: error.response.data.error_description,
                                            /*autoHideDelay: 99999999,*/
                                        },
                                    });

                                    this.loading = false;
                                }
                                else if (errorMsg == "Empirely_attempt") {
                                    const externalURL = 'https://app.empirely.ai';
                                    const link = "This email belong to Empirely account. Click <a href='" + externalURL + "'> Here</a> to access Landingly";
                                    this.error = link;
                                    this.loading = false;
                                }
                                else {
                                    this.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',

                                        props: {
                                            title: 'Invalid Login Attempt',
                                            icon: 'BellIcon',
                                            variant: 'danger',
                                            text: error.response.data.error_description,
                                            /*autoHideDelay: 99999999,*/
                                        },
                                    });

                                    this.loading = false;
                                }
                                this.$refs.loginForm.setErrors(error.response.data.error)
                                this.loading = false;
                            })
                    }
                })
            },

            AutoLogin(loginemail, code) {
                //axios.post('' + this.WebAPIURL + "/token", qs.stringify(data))
                this.loading = true;
                useJwt.login({
                    email: loginemail,
                    password: code,
                    IsAllow: 'true'
                })
                    .then(response => {
                        const ability = [
                            {
                                action: 'manage',
                                subject: 'all',
                            },
                        ]
                        
                        response.data.ability = ability
                        var expirytime = $themeConfig.app.RefreshTokenExpiryTime;
                        useJwt.setToken(response.data.access_token)
                        //console.log('refresh token:: ' + response.data.refresh_token);
                        useJwt.setRefreshToken(response.data.refresh_token)
                        localStorage.setItem('User_Name', JSON.stringify(response.data.fullName))
                        useJwt.setUserEmail(JSON.stringify(response.data.email));
                        useJwt.setUserRole(response.data.role);
                        useJwt.setUserId(response.data.userId);
                        var currDate = new Date();
                        var expiryDate = new Date(currDate.setSeconds(currDate.getSeconds() + response.data.expires_in));
                        //console.log(expiryDate);
                        useJwt.setTokenExpiry(expiryDate);
                        var refreshTokenExpiryTime = new Date(currDate.setSeconds(currDate.getSeconds() + expirytime));
                        // console.log(refreshTokenExpiryTime);
                        useJwt.setRefreshTokenExpiry(refreshTokenExpiryTime);
                        console.log(response.data.subDomain);
                        this.$ability.update(ability)
                        this.submdomain = response.data.subDomain;
                        this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', 3)
                        this.loading = false;
                        if (response.data.subDomain == null || response.data.subDomain == "") {
                            this.$router.replace('/setupuseraccount')
                        }
                        else {
                            useJwt.setSubDomain(response.data.subDomain);
                            this.$router.replace(getHomeRouteForLoggedInUser(response.data.role))
                                .then(() => {
                                    this.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',
                                        props: {
                                            title: `Welcome ${response.data.fullName || response.data.username}`,
                                            icon: 'CoffeeIcon',
                                            variant: 'success',
                                            text: `You have successfully logged in. Now you can start to explore.`,
                                        },
                                    })
                                })
                        }
                    })
                    .catch(error => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',

                            props: {
                                title: 'Invalid Login Attempt',
                                icon: 'BellIcon',
                                variant: 'danger',
                                text: error.response.data.error_description,
                            },
                        })
                        this.processing = false;
                        this.$refs.loginForm.setErrors(error.response.data.error)
                        this.loading = false;
                    });
            }
        },
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';

    .brand-logo svg {
        height: 70px;
    }
    .error {
        color: red;
    }

</style>